import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        /* outline: 1px dotted rgba(0, 0, 0, 0.3); */
    }

    html, body {
        margin: 0;
        padding: 0;
    }

    body {
        background-color: ${(p) => p.theme.colors.bg};
        font-family: 'AktivGrotesk', sans-serif;
        -webkit-font-smoothing: antialiased;
    }

    @font-face {
        font-family: 'AktivGrotesk';
        font-weight: 300;
        src: url('/fonts/aktivgrotesk-light-webfont.woff2') format('woff2'), 
             url('/fonts/aktivgrotesk-light-webfont.woff') format('woff'); 
    }

    @font-face {
        font-family: 'AktivGrotesk';
        font-weight: 400;
        src: url('/fonts/aktivgrotesk-regular-webfont.woff2') format('woff2'), 
             url('/fonts/aktivgrotesk-regular-webfont.woff') format('woff'); 
    }

    @font-face {
        font-family: 'AktivGrotesk';
        font-weight: 600;
        src: url('/fonts/aktivgrotesk-bold-webfont.woff2') format('woff2'), 
             url('/fonts/aktivgrotesk-bold-webfont.woff') format('woff'); 
    }
`;

export default GlobalStyles;
