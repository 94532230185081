const theme = {
  colors: {
    // bg: '#eee',
    // phoneBg: '#fff',
    // color1: 'rgba(0, 0, 0, 0.05)',
    // color2: 'rgba(0, 0, 0, 0.05)',
    // color3: 'rgba(0, 0, 0, 0.05)',
    // text: '#fff',
    // ----
    bg: "#070819",
    phoneBg: "#070819",
    color1: "#559BFF",
    color2: "#5C35FE",
    color3: "#B600FC",
    text: "#fff",
  },
  dimensions: {
    minWidth: 1200,
    minHeight: 700,
  },
  bp: {
    sm: 768,
    md: 1280,
    lg: 1500,
    xl: 1800,
  },
  animation: true,
};

export default theme;
